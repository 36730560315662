<template>
  <CForm ref="form" class="needs-validation">
    <CSelect
        label="Kargo Şirketi: "
        placeholder="Seçiniz.."
        horizontal
        :options="shippingCompanies"
        :value.sync="data.shippingCompany"
        required
    />
    <CInput
        label="Başlangıç Tarihi"
        type="datetime-local"
        horizontal
        v-model="data.startDate"
    />
    <CInput
        label="Bitiş Tarihi"
        type="datetime-local"
        horizontal
        v-model="data.endDate"
    />
  </CForm>
</template>

<script>
export default{
  name: "ETGBForm",
  props:{
    validated: Boolean,
    params: Object,
    actionType: String,
  },
  watch:{
    params: function(val){
      this.data = {...val};
    },
    actionType:  function(val){
      if(val == 'create'){
        this.data = {...this.params};
      }
    },
  },
  data: function(){
    return{
      data: this.params ? {...this.params} : {},
      shippingCompanies: [{value: 4, label: "DHL"}, {value: 6, label: "UPS"}]
    }
  }
}
</script>